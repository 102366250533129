module.exports = {
  DEFAULT_COUNT: 145,
  ENVIRONMENT: process.env.NODE_ENV,
  FULL_DATE_FORMAT: 'yyyy-MM-dd',
  GOAL_KEY: 'TRACKS_GOAL',
  REST_ENDPOINT: process.env.REACT_APP_API_ENDPOINT,
  STORAGE_KEY: 'TRACKS_DEFAULT',
  HIGHEST_WEIGHT: 175,
  LOWEST_WEIGHT: 115,
};
